import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import { SectionWrap, MenuRow, MenuItem, ImageWrapper } from './style'

const JumpMenu = ({ themes }) => {
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col md={12}>
            <Heading textalign="center" mb="50px">
              Bekijk alle thema's
            </Heading>
          </Col>
        </Row>

        <MenuRow>
          {themes.map(({ id, title, image }) => (
            <Col xs={4} md={4} lg={2} key={`jump-link-${id}`}>
              <MenuItem offset="100" href={`#section-${id}`}>
                <ImageWrapper>
                  <img src={image.file.url} alt={title} />
                </ImageWrapper>

                <span>{title}</span>
              </MenuItem>
            </Col>
          ))}
        </MenuRow>
      </Container>
    </SectionWrap>
  )
}

JumpMenu.defaultProps = {
  layout: 0,
}

JumpMenu.propTypes = {
  layout: PropTypes.number,
}

export default JumpMenu

import styled from 'styled-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Row } from '../../../components/ui/wrapper'
import { device } from '../../../theme'

export const SectionWrap = styled.section`
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  z-index: 5;
`

export const MenuRow = styled(Row)`
  align-items: start;
  justify-content: center;

  @media ${device.small} {
    margin-bottom: -50px;
  }

  > * {
    text-align: center;
  }
`

export const MenuItem = styled(AnchorLink)`
  position: relative;

  @media ${device.medium} {
    margin-bottom: 20px;
  }

  &:hover span {
    border-color: ${(props) => props.theme.colors.theme};
  }

  span:last-child {
    display: block;
    font-family: ${(props) => props.theme.fontFamily.heading};
  }
`

export const ImageWrapper = styled.span`
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  border: 1px solid ${(props) => props.theme.colors.border};

  @media ${device.xxsmall} {
    width: 75px;
    height: 75px;

    img {
      width: 60px;

      @supports (object-fit: contain) {
        height: 60px;
      }
    }
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 85px;
    transform: translate(-50%, -50%);

    @supports (object-fit: contain) {
      height: 85px;
      object-fit: contain;
    }
  }
`

import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SEO from '../components/seo'
import Header from '../containers/header'
import Footer from '../containers/footer'
import Hero from '../components/hero'
import PageSlider from '../containers/page-slider'
import JumpMenu from '../containers/theme/jump-menu'
import SplitBlock from '../containers/theme/split-block'

const ThemePage = ({ pageContext, location }) => {
  const pageData = useStaticQuery(graphql`
    query ThemepageQuery {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl
          defaultImage: image
        }
      }

      page: allContentfulPagina(
        filter: { id: { eq: "69df079e-f869-572e-81d2-f4ab2c57c68e" } }
      ) {
        edges {
          node {
            headerTitel
            headerSubtitel
            headerButtonTekst
            headerButtonLink
            headerImage {
              file {
                url
                fileName
                contentType
              }
              fluid(maxWidth: 900) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }

      themes: allContentfulThema(sort: { fields: title }) {
        edges {
          node {
            __typename
            id
            title
            url
            color
            description {
              description
            }
            image {
              file {
                url
                fileName
                contentType
              }
            }
            icon {
              file {
                url
                fileName
                contentType
              }
            }
          }
        }
      }

      subthemes: allContentfulOnderwerp(
        filter: { theme: { title: { regex: "^$|s+/gm" } } }
      ) {
        edges {
          node {
            id
            title
            slug
            themes {
              id
            }
          }
        }
      }
    }
  `)

  const metadata = pageData.site.siteMetadata
  metadata.url = `${metadata.siteUrl}/themas`
  const {
    headerTitel,
    headerSubtitel,
    headerButtonTekst,
    headerButtonLink,
    headerImage,
  } = pageData.page.edges[0].node
  const themes = pageData.themes.edges.map((edge) => edge.node)
  const subthemes = pageData.subthemes.edges.map((edge) => edge.node)
  const [flyoutOpen, setFlyoutOpen] = useState(false)

  const flyoutHandler = () => setFlyoutOpen((prevState) => !prevState)

  return (
    <>
      <SEO
        title={`${headerTitel}`}
        description={headerSubtitel || headerTitel}
        image={headerImage?.file?.url}
        metadata={metadata}
      />

      <Header
        transparent
        flyoutOpen={flyoutOpen}
        flyoutHandler={flyoutHandler}
      />
      <main id="main" className="site-wrapper-reveal">
        <Hero
          flyoutHandler={flyoutHandler}
          title={headerTitel}
          description={headerSubtitel}
          buttontext={headerButtonTekst}
          buttonlink={headerButtonLink}
          image={headerImage}
          pageContext={pageContext}
        />

        <PageSlider
          list={themes}
          btnText="Bekijk dit thema"
          path="/themas"
          category="Thema"
          offset={true}
        />

        <JumpMenu themes={themes} />

        {themes.map(({ id, title, description, icon, url }, index) => (
          <SplitBlock
            index={index}
            key={id}
            identifier={id}
            title={title}
            description={description.description}
            icon={icon}
            url={`/themas/${url}`}
            layout={index % 2}
            subthemes={subthemes}
          />
        ))}
      </main>
      <Footer />
    </>
  )
}

export default ThemePage
